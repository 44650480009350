import {useEffect} from 'react';
import {useMap} from 'react-map-gl';

type StyleLoadedGuardProps = {
  // this state has to come from outside the StyleLoadedGuard component as otherwise
  // it'll get cleared if/when the map changes. And the guard has to be its own component
  // as it seems like that's the only way to get a ref to the map via useMap()...
  guardState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]; // useState(false)
  children?: React.ReactNode;
};
const StyleLoadedGuard: React.FC<StyleLoadedGuardProps> = props => {
  const mapRef = useMap();
  const [styleLoaded, setStyleLoaded] = props.guardState;
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;

      const onStyleLoad = () => {
        setStyleLoaded(true);
      };
      const onStyleUnload = () => {
        setStyleLoaded(false);
      }
      map.on('style.load', onStyleLoad);
      map.on('load', onStyleLoad);
      map.on('styledataloading', onStyleUnload)
      if (map.isStyleLoaded()) {
        onStyleLoad();
      }
      return () => {
        map.off('style.load', onStyleLoad);
        map.off('load', onStyleLoad);
        map.off('styledataloading', onStyleUnload);
      };
    } else {
      return undefined;
    }
  }, [mapRef]);

  return styleLoaded && props.children;
}

export default StyleLoadedGuard;
