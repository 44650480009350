import { useEffect } from "react";
import { useMap } from "react-map-gl";
import arrow from "../../resources/arrow.png";

export default function MapImages() {
	const {current: map} = useMap();
	useEffect(() => {
		if (map?.getMap()) {
			if (map.getMap().hasImage("arrow-sdf")) {
				console.log("already has image");
				return;
			}
			map.getMap().loadImage(arrow, (error, image) => {
				console.log("loaded image", image, error);
				if (image) {
					map.getMap().addImage("arrow-sdf", image, {
						sdf: true,
					});
					console.log("added image");
				}
			});
		}
	}, [map]);

	return null;
}