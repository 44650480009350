import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapPage.css"
import Map, { GeolocateControl, Layer, type MapRef, NavigationControl, ScaleControl, Source } from "react-map-gl";
import RoutesSource from "@/components/maps/RoutesSource";
import StyleLoadedGuard from "@/components/StyleLoadedGuard";
import { useEffect, useMemo, useRef, useState } from "react";
import RouteStops from "@/components/maps/RouteStops";
import { decode } from "@googlemaps/polyline-codec";
import Buses from "@/components/maps/Buses";
import ResizeMap from "@/components/ResizeMap";
import MapModal from "@/components/MapModal";
import { setCurrentlyOpenModal, useStore } from "@/data/store";
import MapImages from "@/components/MapImages";
import { GeolocateControl as MapboxGeolocateControl } from "mapbox-gl";

const MapPage: React.FC = () => {
  const styleLoadedGuardState = useState(false);
  const [deviceColorScheme, setDeviceColorScheme] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
  const connectionUnstable = useStore(state => state.connectionUnstable);
  const mapStyle = useStore(state => state.mapStyle);
  const routesForSchedule = useStore(state => state.routesForSchedule);
  const geolocateControl = useRef<MapboxGeolocateControl>(null);
  const [hasDoneInitialLocation, setHasDoneInitialLocation] = useState(false);
  const geolocateOnLoad = useStore(state => state.geolocateOnLoad);
  const [pendingFocusOnAddressId, setPendingFocusOnAddressId] = useState<number | null>(null);
  const mapboxRef = useRef<MapRef>();

  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    const onChange = (event: MediaQueryListEvent) => {
      setDeviceColorScheme(event.matches ? "dark" : "light");
    }
    matchMedia.addEventListener("change", onChange);
    setDeviceColorScheme(matchMedia.matches ? "dark" : "light");
    return () => {
      matchMedia.removeEventListener("change", onChange);
    }
  }, []);

  useEffect(() => {
    if (geolocateControl.current && !hasDoneInitialLocation && geolocateOnLoad) {
      geolocateControl.current.trigger();
      
      setHasDoneInitialLocation(true);
    }
  }, [geolocateControl.current, hasDoneInitialLocation, geolocateOnLoad]);



  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const addressId = search.get("addressId");

    if (addressId) {
      setCurrentlyOpenModal({
        type: "routeStop",
        addressId: parseInt(addressId),
      });
      const newUrl = new URL(window.location.href);
      newUrl.search = "";
      window.history.replaceState(undefined, "", newUrl.href);
      setPendingFocusOnAddressId(parseInt(addressId));
    }
  }, []);

  useEffect(() => {
    if (pendingFocusOnAddressId && mapboxRef.current) {
      const stop = routesForSchedule?.flatMap(route => route.Stops).find(stop => stop.AddressID === pendingFocusOnAddressId);
      if (stop) {
        mapboxRef.current.flyTo({
          center: [stop.Longitude, stop.Latitude],
          zoom: 16,
        });
        setPendingFocusOnAddressId(null);
      }
    }
  }, [pendingFocusOnAddressId, mapboxRef.current, routesForSchedule, routesForSchedule?.length]);

  const colorScheme = useMemo(() => {
    if (mapStyle === "dark") {
      return "dark";
    } else if (mapStyle === "light") {
      return "light";
    } else {
      return deviceColorScheme;
    }
  }, [deviceColorScheme, mapStyle])

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonToast
          isOpen={connectionUnstable}
          position="top"
          message="The connection is unstable. Data may be outdated."
        ></IonToast>
        <Map
          reuseMaps
          // @ts-ignore
          ref={mapboxRef}
          mapboxAccessToken="pk.eyJ1IjoiY2F0Z2lybGluc3BhY2UiLCJhIjoiY20wbHBrZmFqMDhnazJscHl0MWZpd2pxMCJ9.mjZrhiYtPRoYzwmJr_MA9w"
          initialViewState={{
            longitude: -86.176048,
            latitude: 39.774019,
            zoom: 14,
          }}
          maxBounds={
            [
              [-86.19697, 39.76336],
              [-86.15122, 39.78839],
            ]
          }
          style={{ width: "100%", height: "100%" }}
          mapStyle={colorScheme === "dark" ? "mapbox://styles/catgirlinspace/cm08suzqb00r401qvc381bd43" : "mapbox://styles/catgirlinspace/cm0789ve100ps01o0hb1h2f3z"}
        >
          <MapImages />
          <ResizeMap />
          <GeolocateControl showUserHeading={true} trackUserLocation={true} ref={geolocateControl} />
          <NavigationControl />
          <ScaleControl />
          <StyleLoadedGuard guardState={styleLoadedGuardState}>
            <RoutesSource />
            <RouteStops colorScheme={colorScheme} />
            <Buses />
          </StyleLoadedGuard>
        </Map>
        <MapModal />
      </IonContent>
    </IonPage>
  );
};

export default MapPage;
