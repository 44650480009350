import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
  setupIonicReact,
  useIonToast,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { settings, map, bus } from "ionicons/icons";
import MapPage from "./pages/MapPage";
import Tab2 from "./pages/Debug";
import Tab3 from "./pages/Settings";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css'; */
import "@ionic/react/css/palettes/dark.system.css";

/* Theme variables */
import "./theme/variables.css";
import { useEffect, useState } from "react";
import { MapState, setConnectionUnstable, setCurrentlyOpenModal, setMapVehiclePoints, setRoutesForSchedule, setStopArrivalTimes, setVehicleCapacities, useStore } from "./data/store";
import { clone, patch } from "jsondiffpatch";
import Feedback from "./pages/Feedback";

setupIonicReact();

const eventSourceEvents: Array<{
  event: string,
  storeProperty: keyof MapState,
  diff?: boolean,
  setter: (data: any) => void,
}> = [
  {
    event: "positions",
    storeProperty: "mapVehiclePoints",
    setter: setMapVehiclePoints,
  },
  {
    event: "capacities",
    storeProperty: "vehicleCapacities",
    setter: setVehicleCapacities,
  },
  {
    event: "routes",
    storeProperty: "routesForSchedule",
    setter: setRoutesForSchedule,
  },
  {
    event: "stops",
    storeProperty: "stopArrivalTimes",
    setter: setStopArrivalTimes,
  },
  {
    event: "positions-diff",
    storeProperty: "mapVehiclePoints",
    diff: true,
    setter: setMapVehiclePoints,
  },
  {
    event: "capacities-diff",
    storeProperty: "vehicleCapacities",
    diff: true,
    setter: setVehicleCapacities,
  },
  {
    event: "routes-diff",
    storeProperty: "routesForSchedule",
    diff: true,
    setter: setRoutesForSchedule,
  },
  {
    event: "stops-diff",
    storeProperty: "stopArrivalTimes",
    diff: true,
    setter: setStopArrivalTimes,
  },
]

const App: React.FC = () => {
  const [present] = useIonToast();
  const [hasConnectedAtLeastOnce, setHasConnectedAtLeastOnce] = useState(false);

  useEffect(() => {

    // opening a connection to the server to begin receiving events from it
    const eventSource = new EventSource(
      "https://jag-line-server.fly.dev/datastream"
    );

    eventSource.onerror = (event) => {
      console.log(event)
      setConnectionUnstable(true);
    }
    
    for (const eventSourceEvent of eventSourceEvents) {
      eventSource.addEventListener(eventSourceEvent.event, (event) => {
        setHasConnectedAtLeastOnce(true);
        setConnectionUnstable(false);
        if (!eventSourceEvent.diff) {
          const data = JSON.parse(event.data);
          eventSourceEvent.setter(data);
        } else {
          const delta = JSON.parse(event.data);
          const storeData = useStore.getState()[eventSourceEvent.storeProperty];
          const oldData = clone(storeData);
          patch(oldData, delta);
          eventSourceEvent.setter(oldData);
        }
      });
    }

    // terminating the connection on component unmount
    return () => eventSource.close();
  }, []);

  return (
    <IonApp>
      <IonToast
        isOpen={!hasConnectedAtLeastOnce}
        position="top"
        message="Waiting for data..."
      ></IonToast>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/map">
              <MapPage />
            </Route>
            <Route exact path="/settings/debug">
              <Tab2 />
            </Route>
            <Route exact path="/settings/feedback">
              <Feedback />
            </Route>
            <Route exact path="/settings">
              <Tab3 />
            </Route>
            <Route exact path="/">
              <Redirect to="/map" />
            </Route>
            <Route exact path="/map/routes">
              <Redirect to="/map" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom" id="tab-bar">
            <IonTabButton tab="routeSelection" href="/map/routes" onClick={() => { setCurrentlyOpenModal({ type: "routeSelection" })}}>
              <IonIcon aria-hidden="true" icon={bus} />
              <IonLabel>Routes</IonLabel>
            </IonTabButton>
            <IonTabButton tab="map" href="/map">
              <IonIcon aria-hidden="true" icon={map} />
              <IonLabel>Map</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab3" href="/settings">
              <IonIcon aria-hidden="true" icon={settings} />
              <IonLabel>Settings</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
