import { setCurrentlyOpenModal, useStore } from "@/data/store";
import { RouteStopModal, VehicleModal } from "@/data/types";
import { IonContent, IonItem, IonLabel, IonList, IonModal } from "@ionic/react";
import { intlFormatDistance } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";

function convertDateString(dateString: string): Date {
	// Extract the timestamp using a regular expression
	const timestampMatch = /\/Date\((\d+)\)\//.exec(dateString);

	if (timestampMatch) {
		// Convert the extracted timestamp to a number
		const timestamp = parseInt(timestampMatch[1], 10);
		// Create and return a new Date object
		return new Date(timestamp);
	} else {
		throw new Error("Invalid date format");
	}
}

export default function Vehicle({ vehicleModal }: { vehicleModal: VehicleModal }) {
	const vehicles = useStore((state) => state.mapVehiclePoints);
	const capacities = useStore((state) => state.vehicleCapacities);
	const routesForSchedule = useStore((state) => state.routesForSchedule);
	const stopArrivalTimes = useStore((state) => state.stopArrivalTimes);
	const [currentDate, setCurrentDate] = useState(new Date()); // Save the current date to be able to trigger an update

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentDate(new Date());
		}, 1000);
		return () => {
			clearInterval(timer);
		}
	}, []);
	
	const currentVehicle = useMemo(() => {
		return vehicles?.find((vehicle) => vehicle.VehicleID === vehicleModal.vehicleId);
	}, [vehicles, vehicleModal.vehicleId]);
	const currentCapacity = useMemo(() => {
		return capacities?.find((capacity) => capacity.VehicleID === vehicleModal.vehicleId);
	}, [capacities, vehicleModal.vehicleId]);
	const currentRoute = useMemo(() => {
		return routesForSchedule?.find((route) => route.RouteID === currentVehicle?.RouteID);
	}, [routesForSchedule, currentVehicle?.RouteID]);
	const upcomingStops = useMemo(() => {
		return stopArrivalTimes?.filter(arrivalTime => {
			return arrivalTime.Times.find(time => time.VehicleId === vehicleModal.vehicleId);
		});
	}, [stopArrivalTimes, vehicleModal.vehicleId]);
	const upcomingStopsLength = upcomingStops?.length || 0;

	return <IonContent>
		<div className="ion-padding">
			<h1>Bus { currentVehicle?.Name.trim() } - { currentRoute?.Description.replace("Estimated Times", "").trim() }</h1>
			<h2>{ currentCapacity?.CurrentOccupation }/{ currentCapacity?.Capacity }</h2>
			
			<h2>Upcoming Stops</h2>
			{ upcomingStopsLength === 0 && <p>No upcoming stops</p> }
		</div>
		{ upcomingStopsLength > 0 && <IonList>
			{ upcomingStops?.sort((a, b) => {
				const stopA = currentRoute?.Stops.find(stop => stop.RouteStopID === a.RouteStopId);
				const stopB = currentRoute?.Stops.find(stop => stop.RouteStopID === b.RouteStopId);
				const stopEstimatedTimeA = a.Times.find(time => time.VehicleId === vehicleModal.vehicleId)?.EstimateTime;
				const stopEstimatedTimeB = b.Times.find(time => time.VehicleId === vehicleModal.vehicleId)?.EstimateTime;
				return convertDateString(stopEstimatedTimeA!).getTime() - convertDateString(stopEstimatedTimeB!).getTime();
			}).map((arrivalTime) => {
				const stop = currentRoute?.Stops.find(stop => stop.RouteStopID === arrivalTime.RouteStopId);
				const stopEstimatedTime = arrivalTime.Times.find(time => time.VehicleId === vehicleModal.vehicleId)?.EstimateTime;
				const estimatedTime = convertDateString(stopEstimatedTime!);
				const arrivingVerySoon = estimatedTime.getTime() - currentDate.getTime() < 10 * 1000;
				const arrivedInPast = estimatedTime.getTime() - currentDate.getTime() < 0;
				const comparisonTime = (arrivingVerySoon || arrivedInPast) ? currentDate : estimatedTime;

				let relativeTime = intlFormatDistance(comparisonTime, currentDate);
				if (arrivingVerySoon || arrivedInPast) {
					relativeTime = "now";
				} else if (estimatedTime.getTime() - currentDate.getTime() < 60 * 1000) {
					let rounded = Math.round((estimatedTime.getTime() - currentDate.getTime()) / 15000) * 15;
					if (rounded === 60) {
						relativeTime = "in 1 minute";
					}
					relativeTime = `in ${rounded} seconds`;
				}

				return <IonItem key={arrivalTime.RouteStopId}>
					<IonLabel>{ stop?.Description }</IonLabel>
					<IonLabel slot="end" style={{textAlign: "right"}}>
						{ relativeTime }
						<p style={{textAlign: "right"}}>
							{ estimatedTime.toLocaleTimeString('en-US') }
						</p>
					</IonLabel>
				</IonItem>
			}) }
		</IonList> }
	</IonContent>
}