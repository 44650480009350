import { useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { useMap } from "react-map-gl";

export default function ResizeMap() {
	const {current: map} = useMap();
	const { routeInfo } = useIonRouter();
	map?.getMap().resize();
	requestAnimationFrame(() => {
		map?.getMap().resize();
	});
	map?.getMap().on("load", () => map?.getMap().resize());
	useEffect(() => {
		map?.getMap().resize();
		requestAnimationFrame(() => {
			map?.getMap().resize();
		});
	}, [routeInfo, map]);
	return undefined;
}
