import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { RoutesForSchedule, MapVehiclePoints, ModalType, VehicleCapacities, StopArrivalTimes } from "./types";

export interface MapState {
  routesForSchedule?: RoutesForSchedule;
  mapVehiclePoints?: MapVehiclePoints;
  vehicleCapacities?: VehicleCapacities;
  stopArrivalTimes?: StopArrivalTimes;
  currentlyOpenModal: ModalType;
  connectionUnstable: boolean;
  enabledRouteIds: Map<number, boolean> | undefined;
  mapStyle: "default" | "light" | "dark";
  geolocateOnLoad: boolean;
}

export const useStore = create<MapState>()(persist(
  (set, get: () => MapState) => ({
    routesForSchedule: [],
    mapVehiclePoints: [],
    vehicleCapacities: [],
    stopArrivalTimes: [],
    currentlyOpenModal: undefined,
    connectionUnstable: false,
    enabledRouteIds: undefined,
    mapStyle: "default",
    geolocateOnLoad: false,
  }),
  {
    name: "state-storage", // name of the item in the storage (must be unique)
    storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    partialize: (state) => ({
      // enabledRouteIds: state.enabledRouteIds,
      mapStyle: state.mapStyle,
      geolocateOnLoad: state.geolocateOnLoad,
    })
  }
));


export const setRoutesForSchedule = (routesForSchedule: RoutesForSchedule) => {
  useStore.setState({ routesForSchedule });
  if (useStore.getState().enabledRouteIds === undefined) {
    useStore.setState({
      enabledRouteIds: new Map(routesForSchedule.map(route => [route.RouteID, true])),
    });
  }
}

export const setMapVehiclePoints = (mapVehiclePoints: MapVehiclePoints) => {
  useStore.setState({ mapVehiclePoints });
}

export const setVehicleCapacities = (vehicleCapacities: any[]) => {
  useStore.setState({ vehicleCapacities });
}

export const setStopArrivalTimes = (stopArrivalTimes: StopArrivalTimes) => {
  useStore.setState({ stopArrivalTimes });
}

export const setCurrentlyOpenModal = (currentlyOpenModal: MapState['currentlyOpenModal']) => {
  useStore.setState({ currentlyOpenModal });
}

export const setConnectionUnstable = (connectionUnstable: boolean) => {
  useStore.setState({ connectionUnstable });
}

export const setEnabledRouteIds = (enabledRouteIds: Map<number, boolean> | undefined) => {
  useStore.setState({ enabledRouteIds });
}

export const setRouteIdEnabled = (routeId: number, enabled: boolean) => {
  const enabledRouteIds = new Map(useStore.getState().enabledRouteIds);
  enabledRouteIds.set(routeId, enabled);
  useStore.setState({ enabledRouteIds });
}

export const setMapStyle = (mapStyle: MapState['mapStyle']) => {
  useStore.setState({ mapStyle });
}

export const setGeolocateOnLoad = (geolocateOnLoad: MapState['geolocateOnLoad']) => {
  useStore.setState({ geolocateOnLoad });
}
