import { MapState, setCurrentlyOpenModal, useStore } from "@/data/store";
import { MapVehiclePoints } from "@/data/types";
import { useMemo } from "react";
import { Marker } from "react-map-gl";

export default function Bus(props: {bus: MapVehiclePoints[0]}) {
	const routesForSchedule = useStore(state => state.routesForSchedule);
	const enabledRouteIds = useStore(state => state.enabledRouteIds);

	const busColor = useMemo(() => {
		if (!routesForSchedule) {
            console.log("Routes not loaded");
            return "black";
        }
        const route = routesForSchedule.find(route => route.RouteID === props.bus.RouteID);
        if (!route) {
            console.log("Route not found for routeID: ", props.bus.RouteID);
            return "purple";
        }

        return route.MapLineColor;
	}, [routesForSchedule, props.bus, props.bus.RouteID, routesForSchedule?.length]);

	if (!enabledRouteIds?.get(props.bus.RouteID)) {
		return null;
	}

	return (
		<Marker
			key={props.bus.VehicleID}
			latitude={props.bus.Latitude}
			longitude={props.bus.Longitude}
			onClick={() => setCurrentlyOpenModal({ type: "vehicle", vehicleId: props.bus.VehicleID })}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" style={{fill: busColor, stroke: 'white', strokeWidth: '0.5rem'}} viewBox="0 0 512 512"><path d="M400 32H112a48 48 0 00-48 48v320a47.91 47.91 0 0016 35.74V454a26 26 0 0026 26h28a26 26 0 0026-26v-6h192v6a26 26 0 0026 26h28a26 26 0 0026-26v-18.26A47.91 47.91 0 00448 400V80a48 48 0 00-48-48zM147.47 399.82a32 32 0 1128.35-28.35 32 32 0 01-28.35 28.35zM236 288H112a16 16 0 01-16-16V144a16 16 0 0116-16h124a4 4 0 014 4v152a4 4 0 01-4 4zm20-192H112.46c-8.6 0-16-6.6-16.44-15.19A16 16 0 01112 64h287.54c8.6 0 16 6.6 16.44 15.19A16 16 0 01400 96H256zm20 32h124a16 16 0 0116 16v128a16 16 0 01-16 16H276a4 4 0 01-4-4V132a4 4 0 014-4zm60.18 243.47a32 32 0 1128.35 28.35 32 32 0 01-28.35-28.35z"/></svg>
		</Marker>
	);
}