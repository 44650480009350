import { useStore } from "@/data/store";
import { useMemo } from "react";
import { Marker } from "react-map-gl";
import Bus from "./Bus";

export default function Buses() {
	const buses = useStore(state => state.mapVehiclePoints);

	return (
		<>
			{buses?.map((bus) => (
				<Bus key={bus.VehicleID} bus={bus} />
			))}
		</>
	);
}