import { setCurrentlyOpenModal, useStore } from "@/data/store";
import { IonContent, IonModal } from "@ionic/react";
import { useMemo, useRef } from "react";
import RouteStop from "./modal/RouteStop";
import { ModalType } from "@/data/types";
import RouteSelection from "./modal/RouteSelection";
import Vehicle from "./modal/Vehicle";

function getModalContent(currentlyOpenModal: ModalType) {
	if (currentlyOpenModal?.type === "routeSelection") {
		return <RouteSelection routeSelection={currentlyOpenModal} />
	} else if (currentlyOpenModal?.type === "routeStop") {
		return <RouteStop routeStop={currentlyOpenModal} />
	} else if (currentlyOpenModal?.type === "vehicle") {
		return <Vehicle vehicleModal={currentlyOpenModal} />
	}
}

export default function MapModal() {
	const currentlyOpenModal = useStore((state) => state.currentlyOpenModal);
	
	return <IonModal isOpen={!!currentlyOpenModal} onDidDismiss={() => setCurrentlyOpenModal(undefined)} initialBreakpoint={0.4} breakpoints={[0, 0.4, 1]}>
		{getModalContent(currentlyOpenModal)}
	</IonModal>
}