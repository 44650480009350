import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import { useStore } from '@/data/store';
import type { MapState } from '@/data/store';
import './Tab2.css';

const cleanNames: { [key: string]: string } = {
  routesForSchedule: "Routes for Schedule",
  mapVehiclePoints: "Map Vehicle Points",
  vehicleCapacities: "Vehicle Capacities",
  stopArrivalTimes: "Stop Arrival Times",
  currentlyOpenModal: "Currently Open Modal",
  connectionUnstable: "Connection Unstable",
  enabledRouteIds: "Enabled Route IDs",
  mapStyle: "Map Style",
  geolocateOnLoad: "Geolocate on Load",
}

const Debug: React.FC = () => {

  const storeState = useStore(state => state);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Debug Info</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton></IonBackButton>
            </IonButtons>
            <IonTitle size="large">Debug Info</IonTitle>
          </IonToolbar>
        </IonHeader>
        {Object.entries(storeState).map(([key, value]) => {
          if (value instanceof Map) {
            value = Object.fromEntries(value.entries());
          }
          return <>
            <h2>{cleanNames[key] ? `${cleanNames[key]} (${key})` : key}</h2>
            <pre>{typeof value}</pre>
            <pre style={{fontSize: "0.75rem"}}>
              {JSON.stringify(value, undefined, ' ')}
            </pre>
          </>
        })}
      </IonContent>
    </IonPage>
  );
};

export default Debug;
