import { AlertOptions } from "@ionic/react";

function urlBase64ToUint8Array(base64String: string) {
	var padding = '='.repeat((4 - base64String.length % 4) % 4);
	var base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	var rawData = window.atob(base64);
	var outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

type PermissionPromptReturn = [true] | [false, AlertOptions];

export async function getPermissionIfNecessary(): Promise<PermissionPromptReturn> {
	if (!localStorage.getItem('clientId') || Notification.permission !== 'granted') {
		console.log(Notification.permission)
		if (Notification.permission !== 'granted') {
			const permission = await Notification.requestPermission();
			if (permission !== 'granted') {
				return [false, {
					header: 'Notifications not allowed',
					message: 'You have to allow notifications to be able to receive notifications',
					buttons: ['OK'],
				}];
			}
		}
		const registration = await navigator.serviceWorker.getRegistration();
		const pushSubscription = await registration?.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array("BKKimSvjoKAFdpmKE9hRc8qOGCaW6I27hnUlS6mOTIibCjceFAxka5pzdLm7N9-lPTpnedWeqTuDa3A4cGs7UjA")
		});
		const clientCreationResponse = await fetch("https://jag-line-server.fly.dev/notifications/client", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				pushSubscription,
			})
		});
		const clientCreationData = await clientCreationResponse.json();
		localStorage.setItem('clientId', clientCreationData.clientId);
	}
	return [true];
}