import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonItem, IonList, IonTextarea, IonInput, IonButton, useIonToast, useIonRouter, IonText } from "@ionic/react";
import { captureFeedback } from "@sentry/react";
import { useState } from "react";

export default function Feedback() {
	const [present] = useIonToast();
	const router = useIonRouter();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [feedback, setFeedback] = useState("");

	const sendFeedback = async () => {
		if (feedback.trim() === "") {
			present({
				message: "Please enter feedback",
				duration: 3000,
				position: "top",
			});
			return;
		}
		captureFeedback({
			name,
			email,
			message: feedback,
		}, {
			includeReplay: true,
		});
		present({
			message: "Thank you for your feedback!",
			duration: 3000,
			position: "top",
		});
		router.goBack();
	}

	return <IonPage>
		<IonHeader>
			<IonToolbar>
				<IonButtons slot="start">
					<IonBackButton></IonBackButton>
				</IonButtons>
				<IonTitle>Send Feedback</IonTitle>
			</IonToolbar>
		</IonHeader>
		<IonContent fullscreen>
			<IonHeader collapse="condense">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton></IonBackButton>
					</IonButtons>
					<IonTitle size="large">Send Feedback</IonTitle>
				</IonToolbar>
			</IonHeader>

			<p className="ion-padding">
				Send feedback to the JAG<b><i>LINE</i></b>++ team.
				For feedback on the JAG<b><i>LINE</i></b> shuttle system, <a href="https://parking.indianapolis.iu.edu/about/contact-us.html" target="_blank">contact Parking and Transportation Services</a>.
			</p>

			<form>
				<IonList>
					<IonItem>
						<IonInput label="Name" value={name} onIonChange={e => setName(e.detail.value!)}></IonInput>
					</IonItem>
					<IonItem>
						<IonInput label="Email" type="email" value={email} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
					</IonItem>
					<IonItem>
						<IonTextarea labelPlacement="floating" autoGrow required value={feedback} onIonChange={e => setFeedback(e.detail.value!)}>
						<div slot="label">
							Feedback <IonText color="danger">(required)</IonText>
						</div>
						</IonTextarea>
					</IonItem>
				</IonList>

				<div className="ion-padding">
					<IonButton expand="block" onClick={sendFeedback}>Send</IonButton>
				</div>
			</form>
		</IonContent>
	</IonPage>
}
