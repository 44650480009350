import { useStore } from "@/data/store";
import { routes } from "../../dummy";
import { decode } from "@googlemaps/polyline-codec";
import React, { useEffect } from "react";
import { Layer, Source, useMap } from "react-map-gl";
import { arrowForward } from "ionicons/icons";

export default function RoutesSource() {
  const { current: map } = useMap();

  const routesForSchedule = useStore(state => state.routesForSchedule);
  const enabledRouteIds = useStore(state => state.enabledRouteIds);

if (routesForSchedule === undefined) return <></>;

  const routeElements: React.JSX.Element[] = [];
  for (const route of routesForSchedule) {
    if (enabledRouteIds !== undefined && !enabledRouteIds.get(route.RouteID)) continue;
    const routePoints = decode(route.EncodedPolyline).map(point => [point[1], point[0]]);
	
    routeElements.push(
      <Source
        key={route.RouteID}
        id={`route-${route.RouteID}`}
        type="geojson"
        data={{
          type: "Feature",
          properties: {
            name: route.Description.replace('Estimated Times', '').trim(),
          },
          geometry: {
            type: "LineString",
            coordinates: routePoints,
          }
        }}
      >
        <Layer
          type="line"
          source={`route-${route.RouteID}`}
		      id={`route-${route.RouteID}-layer`}
          paint={{
            "line-color": route.MapLineColor,
            "line-width": 10,
            "line-emissive-strength": 1,
          }}
        />
        <Layer
          type="symbol"
          source={`route-${route.RouteID}`}
		      id={`route-${route.RouteID}-symbol`}
          layout={{
            "icon-image": 'arrow-sdf',
            "icon-size": 0.25,
            "icon-rotate": 90,
            "symbol-placement": "line",
            "symbol-spacing": 100,
          }}
          paint={{
            "icon-color": route.MapLineColor,
            "icon-emissive-strength": 2,
          }}
        />
      </Source>
    );
  }
  return <>{...routeElements}</>;
}
