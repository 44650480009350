import { decode } from "@googlemaps/polyline-codec";
import { Marker, useMap } from "react-map-gl";
import { Popup } from "mapbox-gl";
import { setCurrentlyOpenModal, useStore } from "@/data/store";
import { Fragment, useMemo } from "react";
import { RoutesForSchedule } from "@/data/types";

export default function RouteStops({ colorScheme }: { colorScheme: string }) {
	const enabledRouteIds = useStore((state) => state.enabledRouteIds);
	const routes = useStore((state) => state.routesForSchedule);

	const stopsByAddressId = useMemo(() => {
		if (routes === undefined) return new Map<number, RoutesForSchedule[0]['Stops']>();
		const routesByAddressId = new Map<number, RoutesForSchedule[0]['Stops']>();
		for (const route of routes) {
			for (const stop of route.Stops) {
				if (!routesByAddressId.has(stop.AddressID)) {
					routesByAddressId.set(stop.AddressID, []);
				}
				routesByAddressId.get(stop.AddressID)?.push(stop);
			}
		}
		return routesByAddressId;
	}, [routes]);

	const routeStopElements: React.JSX.Element[] = [];
	for (const [addressId, stops] of stopsByAddressId.entries()) {
		if (stops.length === 1) {
			const stop = stops[0];
			const route = routes?.find(route => route.RouteID === stop.RouteID)!;
			if (enabledRouteIds !== undefined && !enabledRouteIds.get(route.RouteID)) continue;
			const nextPoint = findNextRoutePoint(route, stop.Latitude, stop.Longitude)

			const rotation = (Math.atan2(nextPoint[1] - stop.Longitude, nextPoint[0] - stop.Latitude) * 180 / Math.PI) - 45;

			routeStopElements.push(
				<Marker
					key={'stop-' + stop.RouteStopID}
					latitude={stop.Latitude}
					longitude={stop.Longitude}
					rotation={rotation}
					rotationAlignment="map"
					pitchAlignment="map"
					color={route.MapLineColor}
					onClick={() => setCurrentlyOpenModal({ type: "routeStop", addressId: stop.AddressID })}
					/*popup={new Popup({ offset: 15, className: 'stopPopup' }).setHTML(`
						<p className="stopRouteName">${route.Description.replace('Estimated Times', '').trim()}</p>
						<p className="stopRouteDescription">${stop.Description}</p>`.trim())}*/
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill={route.MapLineColor} stroke={colorScheme === 'dark' ? '#d3d3d3' : '#d3d3d3'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-arrow-out-up-right"><path d="M22 12A10 10 0 1 1 12 2" /><path d="M22 2 12 12" stroke="#fff" /><path d="M16 2h6v6" stroke="#fff" /></svg>
				</Marker>
			);
		} else {
			if (enabledRouteIds !== undefined) {
				let shouldBeVisible = false;
				for (const routeStop of stops) {
					if (enabledRouteIds.get(routeStop.RouteID)) {
						shouldBeVisible = true;
						break
					}
				}
				if (!shouldBeVisible) continue;
			}
			const { Latitude, Longitude } = stops[0];
			routeStopElements.push(
				<Marker
					key={'address-' + addressId}
					latitude={Latitude}
					longitude={Longitude}
					rotationAlignment="map"
					pitchAlignment="map"
					onClick={() => setCurrentlyOpenModal({ type: "routeStop", addressId })}
					/*popup={new Popup({ offset: 15, className: 'stopPopup' }).setHTML(`
						<p className="stopRouteName">${route.Description.replace('Estimated Times', '').trim()}</p>
						<p className="stopRouteDescription">${stop.Description}</p>`.trim())}*/
				>
					<svg width="32" height="32" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2}}>
						<g transform="matrix(1.07563,0,0,1.20755,-10.7563,-12.0755)">
							<ellipse cx="248" cy="222" rx="238" ry="212" style={{fill:`url(#address-${addressId})`}} />
							<path d="M248,10C379.356,10 486,104.994 486,222C486,339.006 379.356,434 248,434C116.644,434 10,339.006 10,222C10,104.994 116.644,10 248,10ZM248,16.901C120.92,16.901 17.747,108.803 17.747,222C17.747,335.197 120.92,427.099 248,427.099C375.08,427.099 478.253,335.197 478.253,222C478.253,108.803 375.08,16.901 248,16.901Z" style={{fill: "#d3d3d3"}} />
						</g>
						<defs>
							<linearGradient id={`address-${addressId}`} x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-7.65466e-14,424,-476,-6.81844e-14,248,10)">
								{stops.map((routeStop, i, array) => {
									const route = routes?.find(route => route.RouteID === routeStop.RouteID);
									return <Fragment key={`${i}-fragment`}>
										<stop key={`${i}`} offset={i / array.length} style={{ stopColor: route?.MapLineColor, stopOpacity: 1 }} />
										<stop key={`${i}-end`} offset={((i + 1) / array.length) - 0.00001} style={{ stopColor: route?.MapLineColor, stopOpacity: 1 }} />
									</Fragment>;
								})}
							</linearGradient>
						</defs>
					</svg>
				</Marker>
			);
		}
	}
	return routeStopElements;
}

function findNextRoutePoint(route: RoutesForSchedule[0], lat: number, lon: number) {
	const routeCoords = decode(route.EncodedPolyline);
	let minDistance = Infinity;
	let minIndex = 0;
	for (let i = 0; i < routeCoords.length; i++) {
		const distance = Math.sqrt(
			Math.pow(routeCoords[i][0] - lat, 2) + Math.pow(routeCoords[i][1] - lon, 2)
		);
		if (distance < minDistance) {
			minDistance = distance;
			minIndex = i;
		}
	}

	if (minIndex === routeCoords.length - 5) {
		return routeCoords[0];
	} else {
		return routeCoords[minIndex + 5];
	}
}
